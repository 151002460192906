<!-- 雲對講視窗 -->
<template>
  <v-dialog v-model="isShow" max-width="800px" ref="dialog" @input="updateShow" persistent>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2" :style="{ height: $store.getters.isSpeaker ? '48px' : '68px' }">
        <span class="text-h6 mr-1"> 雲對講視窗 - {{ selectHouseHold?.HouseHold || answerData?.callerName }} </span>
        <span>
          (<span class="text-h6" :style="{ color: isConnected ? 'green' : 'gray' }"> {{ isConnected ? "連接成功" : "連接中⋯" }} </span>)
        </span>
        <v-spacer />
      </v-card-title>
      <v-divider />

      <!-- 影像來源 -->
      <div v-if="!$store.getters.isSpeaker" class="px-3 pt-3">
        <div class="text-center">WebCam鏡頭</div>
        <v-select
          :disabled="loading"
          dense
          :items="devices"
          label="影像來源"
          outlined
          v-model="selectCamDevice"
          hide-details
          class="my-2"
          item-text="label"
          return-object
          single-line
          @change="changeCam()"
        >
          <template v-slot:item="{ item }">
            <div class="text-h6">
              <div>{{ item.label }}</div>
            </div>
          </template>
        </v-select>
      </div>

      <v-container>
        <!-- 影像顯示 -->
        <v-row>
          <!-- 影像預覽區 -->
          <v-col cols="6">
            <div v-if="!$store.getters.isSpeaker" class="text-center pb-1">影像預覽</div>
            <div class="cam-box">
              <div class="cam-content2">
                <v-progress-circular v-if="!isCamPlaying" :size="100" :color="$store.getters.getThemeColor" indeterminate></v-progress-circular>
              </div>
              <web-cam
                class="cam-content"
                ref="localWebCam"
                :device-id="deviceId"
                :enable-audio="true"
                @has-audio-input="onHasAudioInput"
                @started="onStarted"
                @stopped="onStopped"
                @error="onError"
                @cameras="onCameras"
                @camera-change="onCameraChange"
                @load-stream="onLoadStream"
              />
            </div>
          </v-col>
          <!-- 遠端影像區 -->
          <v-col cols="6">
            <div v-if="!$store.getters.isSpeaker" class="text-center pb-1">遠端影像</div>
            <div class="cam-box">
              <div class="cam-content2">
                <v-progress-circular v-if="!remoteStream" :size="100" :color="$store.getters.getThemeColor" indeterminate></v-progress-circular>
              </div>
              <video class="cam-content" ref="remoteVideo" autoplay playsinline></video>
            </div>
          </v-col>
        </v-row>

        <!-- 控制面板 -->
        <v-row>
          <v-col class="text-center">
            <v-btn icon outlined x-large :color="hasAudioInput && isAudio ? null : `red`" @click="changeAudioState">
              <v-icon>{{ hasAudioInput && isAudio ? "mdi-microphone" : "mdi-microphone-off" }}</v-icon>
            </v-btn>
            <v-btn class="ml-5 mr-5" icon outlined x-large :color="isVideo ? `blue` : null" @click="changeVideoState">
              <v-icon>{{ isVideo ? "mdi-video" : "mdi-video-off" }}</v-icon>
            </v-btn>
            <v-btn icon outlined x-large color="red" @click="updateStart(false)"><v-icon>mdi-phone-hangup</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import WebCam from "@/components/WebCam.vue";

export default {
  components: {
    WebCam,
  },
  name: "ChatRoomDialog",
  watch: {
    show() {
      this.isShow = this.show;
      !this.deviceId || this.updateStart(this.isShow);
    },
    camera(id) {
      this.deviceId = id;
    },
    devices() {
      if (this.devices.length > 0) {
        this.camera = this.devices[0].deviceId;
        this.deviceId = this.devices[0].deviceId;
      }
    },
  },
  data() {
    return {
      isShow: false,
      loading: false,

      // == WebCam ==

      isError: false,
      isCamPlaying: false,
      camera: null,
      deviceId: null,
      devices: [],
      selectCamDevice: null,
      hasAudioInput: false,

      // == WebRTC ==

      /** 連接狀態 */
      isConnected: false,
      /** 是否為通話請求，true : 發送通話、false : 接受通話 */
      isOffer: null,
      /** 麥克風啟用狀態 */
      isAudio: true,
      /** 攝像頭啟用狀態 */
      isVideo: false,
      /** 通話連接超時(s) */
      connectedTimeout: 30,
      /** 選擇的戶別資料 */
      selectHouseHold: null,
      /** 收到的通話請求資料 */
      answerData: null,
      /** 本地多媒體流 @type {MediaStream} */
      localStream: null,
      /** 遠端多媒體流 @type {MediaStream} */
      remoteStream: null,
      /** 本地UUID */
      uuid: null,
      /** WS 容器 @type {WebSocket} */
      websock: null,
      /** @type {RTCPeerConnection} */
      localPC: null,
      configuration: {
        /** 候選人類型 (強制使用 TURN) */
        // iceTransportPolicy: "relay",
        iceServers: [
          {
            urls: "stun:stun.l.google.com:19302", // Google's public STUN server
          },
          { url: "turn:turn.happylink.com.tw:3478?transport=udp", username: "avisotech-turn", credential: "avisotech27534703" },
          { url: "turn:turn.happylink.com.tw:3478?transport=tcp", username: "avisotech-turn", credential: "avisotech27534703" },
          // { url: "turn:13.115.214.86:3478?transport=udp", username: "lelelink-turn", credential: "lelelink94196040" },
          // { url: "turn:13.115.214.86:3478?transport=tcp", username: "lelelink-turn", credential: "lelelink94196040" },
        ],
      },
    };
  },
  methods: {
    // ===== 外部方法 =====

    /** 發送通話及顯示通話視窗
     * @param houseHold 戶別資料
     */
    sendOffer(houseHold) {
      this.isOffer = true;
      this.selectHouseHold = houseHold;
      this.isShow = true;
      this.isAudio = true;
      this.isVideo = false;
      this.isConnected = false;
      this.updateStart(this.isShow);
      this.startTime();
    },

    /** 接收通話及顯示通話視窗
     * @param {string} callerUID 撥號端 UID
     * @param {string} callerName 撥號端名稱
     * @param {string} sdp 撥號端 sdp
     */
    sendAnswer({ callerUID, callerName, sdp }) {
      this.isOffer = false;
      this.answerData = { callerUID, callerName, sdp };
      this.isShow = true;
      this.isAudio = true;
      this.isVideo = false;
      this.isConnected = false;
      this.updateStart(this.isShow);
    },

    // ===== 內部方法 =====

    /** 開始計算通話請求超時 */
    startTime() {
      this.connectedTimeoutId = setTimeout(() => {
        this.updateStart(false);
        this.$emit("connect-timeout", this.selectHouseHold);
      }, this.connectedTimeout * 1000);
    },

    /** 監聽-視窗啟用狀態 */
    updateShow(b) {
      b || this.updateStart(b);
    },

    /** 監聽-設置攝影設備 */
    changeCam() {
      this.$refs.localWebCam.changeCamera(this.selectCamDevice.deviceId);
    },

    /** 監聽-載入流 */
    onLoadStream(stream) {
      this.isError = false;
      this.localStream = stream;
      console.log("========== onLoadStream");
      this.startConnect();
    },

    /** 監聽-開始攝影 */
    onStarted() {
      this.isCamPlaying = true;
    },

    /** 監聽-停止攝影 */
    onStopped() {
      this.isCamPlaying = false;
    },

    /** 更新啟用狀態 */
    updateStart(isStart, isLocal = true) {
      const webCam = this.$refs.localWebCam;
      if (isStart && this.isError) webCam.setupMedia();
      isStart ? webCam?.start() : webCam.stop();
      if (!isStart) {
        this.websock?.close();
        this.hangup(isLocal);
      }
    },

    /** 監聽-異常錯誤事件 */
    onError(error) {
      this.isError = true;
      console.error("[Intercom] On Error Event : ", error);
      this.$swal({
        icon: "error",
        text: "無法取得相機，請確認相機狀態並開啟權限",
      }).then(() => {
        this.updateStart(false);
      });
    },

    /** 監聽-獲取影像來源 */
    onCameras(cameras) {
      this.devices = cameras;
      if (!this.$common.isEmpty(this.devices)) {
        this.selectCamDevice = this.devices[0];
      }
    },

    /** 監聽-切換影像來源 */
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
    },

    /** 監聽-是否有麥克風輸入 */
    onHasAudioInput(b) {
      this.hasAudioInput = b;
      if (!b) console.log("[Intercom] Not Mic Permissions...");
    },

    // ===== WebRTC 相關方法 =====

    /** 開始 WebRTC 連接流程 */
    startConnect() {
      this.connectWebSocket();
      this.connectWebRTC();
    },

    /** 創建 WebSocket 連接 */
    connectWebSocket() {
      // 產生隨機 UUID
      this.uuid = this.generateUUID();
      // WS 服務的網址
      let url = `wss://ws.happylink.com.tw?Clienttype=4&Clientuuid=${this.uuid}`;
      // 開始建立 WS 連接
      this.websock = new WebSocket(url);
      // 監聽-收到的訊息
      this.websock.onmessage = this.onWebsocketonMessage;
      // 監聽-建立連接
      this.websock.onopen = () => console.log("[WS] Open");
      // 監聽-發生錯誤
      this.websock.onerror = (e) => console.log("[WS] Error : ", e);
      // 監聽-結束連線
      this.websock.onclose = (e) => console.log("[WS] close : ", e);
    },

    /** 監聽 WebSocket 訊息*/
    onWebsocketonMessage(e) {
      let Data = JSON.parse(e.data);
      switch (Data.Action) {
        case "offer": // 表示收到通話請求(目前沒用，APP 端 offer 是靠 API + FCM 來通知的)
          console.log("============= [WS] 收到通話請求:", Data);
          break;

        case "answer": // 表示通話請求已被接受
          this.localPC.setRemoteDescription(
            new RTCSessionDescription({
              type: "answer",
              sdp: Data.Data,
            }),
          );
          break;

        case "callCmd": // 表示通話已掛斷(FCM + WS 同時運行)
          this.updateStart(false, false);
          break;
      }
    },

    /** 創建 WebRTC 連接 */
    async connectWebRTC() {
      // 設置啟用裝置
      const offerOptions = {
        offerToReceiveVideo: 1,
        offerToReceiveAudio: 1,
      };

      this.localPC = new RTCPeerConnection(this.configuration);
      // 監聽 ICE 獲取狀態
      this.localPC.onicecandidate = (e) => {
        if (e.candidate) {
          console.log("[RTC] collect ice");
          clearTimeout(this.timeoutId);
          this.timeoutId = setTimeout(() => (this.isOffer ? this.onSendOffer() : this.onSendAnswer()), 500);
        }
      };

      // 添加事件监听器以便观察 ICE 过程
      this.localPC.addEventListener("icecandidate", (event) => {
        if (event.candidate) {
          console.log("ICE Candidate:", event.candidate);
        } else {
          console.log("All ICE candidates have been sent.");
        }
      });

      // 監聽 ICE 連線狀態
      this.localPC.oniceconnectionstatechange = (e) => {
        console.log("[RTC] onIceStateChange ice : ", e.target.iceConnectionState);
        if (e.target.iceConnectionState === "connected") {
          console.log("[RTC] Connected Stable !");
          this.isConnected = true;
          // 結束連線超時計算
          clearTimeout(this.connectedTimeoutId);
        }
      };

      // 將本地媒體流添加到 Connection 裡
      if (this.localStream) {
        // 判斷當 addTrack 方法不支持時，改用 addStream
        if (this.localPC.addTrack) {
          this.localStream.getTracks().forEach((track) => {
            this.localPC.addTrack(track, this.localStream);
          });
        } else {
          this.localPC.addStream(this.localStream);
        }

        // 設置默認聲音啟用狀態
        this.localStream.getAudioTracks().forEach((track) => {
          track.enabled = this.isAudio;
        });
        // 設置默認影像啟用狀態
        this.localStream.getVideoTracks().forEach((track) => {
          track.enabled = this.isVideo;
        });
      }

      // 監聽遠程流
      this.localPC.ontrack = (event) => {
        if (this.$refs.remoteVideo && !this.remoteStream) {
          this.remoteStream = new MediaStream();
          this.$refs.remoteVideo.srcObject = this.remoteStream;
        }
        this.remoteStream.addTrack(event.track);
      };

      // 監聽遠程流(針對舊版瀏覽器)
      this.localPC.onaddstream = (event) => {
        if (this.$refs.remoteVideo) {
          this.remoteStream = event.stream;
          this.$refs.remoteVideo.srcObject = this.remoteStream;
        }
      };

      // 創建本地描述(SDP)，並設至本身
      if (this.isOffer) {
        await this.localPC.createOffer(offerOptions).then((desc) => this.localPC.setLocalDescription(desc));
      } else {
        // 設置遠端描述(SDP)
        console.log("============ setRemoteDescription");

        let sdp = this.answerData.sdp;
        // 音箱模式時須排除不支持的 SDP
        if (this.$store.getters.isSpeaker) {
          sdp = sdp.replace(/a=extmap-allow-mixed\r\n/g, "");
        }

        await this.localPC.setRemoteDescription(
          new RTCSessionDescription({
            type: "offer",
            sdp,
          }),
        );
        console.log("============ createAnswer");
        await this.localPC.createAnswer(offerOptions).then((desc) => this.localPC.setLocalDescription(desc));
      }
    },

    /** [事件] 發送通話請求 */
    onSendOffer() {
      console.log("[RTC] sendOffer");
      const communityId = this.$store.getters.getSelectCommunity.id;
      const houseHoldId = this.selectHouseHold.id;
      const userId = this.$store.getters.getUser.id;
      const sdp = this.localPC.localDescription.sdp;
      this.$api.sendOffer(communityId, houseHoldId, userId, this.uuid, sdp);
    },

    /** [事件] 接受通話請求 */
    onSendAnswer() {
      console.log("[RTC] sendAnswer");
      const callerUID = this.answerData.callerUID;
      const receiverID = this.uuid;
      const sdp = this.localPC.localDescription.sdp;
      this.$api.sendAnswer(callerUID, receiverID, sdp);
    },

    /** [事件] 變更麥克風狀態 */
    changeAudioState() {
      console.log("[RTC] changeAudioState");
      this.isAudio = !this.isAudio;
      this.localStream.getAudioTracks().forEach((track) => {
        track.enabled = this.isAudio;
      });
    },

    /** [事件] 變更影像狀態 */
    changeVideoState() {
      console.log("[RTC] changeVideoState");
      this.isVideo = !this.isVideo;
      this.localStream.getVideoTracks().forEach((track) => {
        track.enabled = this.isVideo;
      });
    },

    /** [事件] 掛斷
     * @param isLocal 是否為自身觸發
     * */
    hangup(isLocal) {
      console.log("[RTC] hangup");
      this.isShow = false;
      this.localPC?.close();
      this.localPC = null;
      this.remoteStream?.getTracks().forEach((track) => track.stop());
      this.remoteStream?.clone();
      this.remoteStream = null;
      this.websock?.close();
      if (this.$refs.remoteVideo) {
        this.$refs.remoteVideo.srcObject = null;
      }

      clearTimeout(this.connectedTimeoutId);

      if (this.uuid && isLocal) {
        this.$api
          .intercomHangup(this.uuid)
          .then(() => console.log("hangup success"))
          .catch((err) => console.log("hangup error : ", err));
      }

      // 顯示結束通話訊息
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        })
        .fire({
          icon: "success",
          title: "已結束通話",
        });
    },

    /** 產生 UUID */
    generateUUID() {
      // 判斷當 crypto 無法使用時，改用自訂義方法
      if (crypto?.randomUUID) {
        return crypto.randomUUID();
      } else {
        let d = new Date().getTime();
        let d2 = (performance && performance.now && performance.now() * 1000) || 0;
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
          let r = Math.random() * 16;
          if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        });
      }
    },
  },
  computed: {
    device() {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },
};
</script>

<style>
.cam-box {
  width: 100%;
  padding-top: 75%;
  position: relative;
  box-shadow: 0 0 0 2px rgba(170, 170, 170);
  background-color: rgba(170, 170, 170);
}
.cam-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cam-content2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
