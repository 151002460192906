<template>
  <div>
    <!-- 主體內容 -->
    <router-view />
    <!-- 消息通知欄 -->
    <v-snackbar v-model="showNotify" :color="notifyStatus" :timeout="notifyTimeout" top>
      <div>{{ notifyText }}</div>
    </v-snackbar>
    <!-- 雲對講視窗 -->
    <chat-room-dialog ref="chatRoom" />
    <!-- 雲對講-來電鈴聲 -->
    <audio ref="audio" loop src="@/assets/audio/ring-tone.mp3"></audio>
    <!-- 智慧助理 -->
    <smart-assistant v-if="$store.getters.getUser" />
  </div>
</template>

<script>
import Vue from "vue";

import ChatRoomDialog from "@/components/ChatRoomDialog.vue";
import SmartAssistant from "@/components/SmartAssistant.vue";

export default {
  components: {
    ChatRoomDialog,
    SmartAssistant,
  },
  name: "App",
  data() {
    return {
      showNotify: false,
      notifyStatus: "",
      notifyText: "",
      notifyTimeout: 1000,
    };
  },

  mounted() {
    document.title = this.$vuetify.lang.t("$vuetify.appName") + " - " + this.$vuetify.lang.t("$vuetify.slogan");

    // 共用 Toast ，使用方式 :
    // 成功 - $showSuccessNotify(msg)
    // 失敗 - $showErrorNotify(msg)
    Vue.prototype.$showSuccessNotify = this.showSuccessNotify;
    Vue.prototype.$showErrorNotify = this.showErrorNotify;
    // this.$vuetify.theme.dark = true;

    this.startOnOfferListener();
  },
  methods: {
    showSuccessNotify(text) {
      this.notifyStatus = "#43A047";
      this.notifyText = text;
      this.notifyTimeout = 4000;
      this.showNotify = true;
    },
    showErrorNotify(text) {
      this.notifyStatus = "#B71C1C";
      this.notifyText = text;
      this.notifyTimeout = 4000;
      this.showNotify = true;
    },
    /** 開始監聽雲對講通話請求 */
    startOnOfferListener() {
      this.$api.setOnOfferStateListener((data) => {
        // 更新狀態有值時才顯示通知訊息，否則將其關閉
        if (data) {
          // 顯示來電通話訊息
          this.$refs.audio.currentTime = 0;
          this.$refs.audio.play();
          this.$swal({
            icon: "info",
            title: `收到來至 ${data.callerName} 的通話請求`,
            showDenyButton: true,
            denyButtonColor: "#4caf50",
            denyButtonText: "接聽",
            confirmButtonColor: "#f44336",
            confirmButtonText: "拒接",
            allowOutsideClick: false,
            timer: 15 * 1000,
            timerProgressBar: true,
          }).then((result) => {
            if (result.isDenied) {
              this.$api.clearOfferState();
              this.$refs.chatRoom.sendAnswer(data);
            }
            this.$refs.audio.pause();
          });
        } else {
          this.$swal.close();
        }
      });
    },
  },
};
</script>

<style>
div {
  font-family: Circle !important;
  font-size: 17px;
}

.theme {
  background: linear-gradient(147deg, #42a9b9 0%, #33a6b8 67%);
}
</style>

<!-- <style>
@import "./assets/font/font.css";
div {
  font-family: Circle;
}
</style> -->
